import React, { useEffect, useState, useRef } from "react";
import './Youtube.css';
import { useRudraAPI } from "../../../api";

import Header from "../../../components/Header/Header";


import WaveSurfer from "wavesurfer.js";
import Regions from 'wavesurfer.js/dist/plugins/regions.js';


import { useNavigate, useSearchParams } from "react-router-dom";
import { Autocomplete, Button, CloseButton, NumberInput, Select, rem, useMantineTheme, Group, Text, Loader, Switch, Badge, Tooltip } from "@mantine/core";

import { Dropzone, DropzoneProps } from '@mantine/dropzone';
import { IconUpload, IconHeadphones, IconX, IconSearch } from "@tabler/icons-react";
import IconYoutube from "../../../components/icons/Youtube";
import { default as YTPlayer } from 'react-youtube';


function Youtube() {

    const [link, setLink] = useState("");
    const [audioLink, setAudioLink] = useState("");
    const autocompleteRef = useRef(null);

    const [section, setSection] = useState([]);
    const [generateLoading, setGenerateLoading] = useState(false);

    const [hasVocals, setHasVocals] = useState(false);


    let navigate = useNavigate();
    const theme = useMantineTheme();

    const wavesurferRef = useRef(null);
    const [Wavesurfer, setWavesurfer] = useState(null);
    const [waveLoaded, setWaveLoaded] = useState(false);

    const { ytVisual, yt } = useRudraAPI();

    const videoPlayerRef = useRef(null);

    const handleSubmit = (event) => {
        event.preventDefault();

        // Perform your desired action with the link here, e.g., navigate to the link

        // Get link from autocomplete
        const acLink = autocompleteRef.current.value;

        // TODO: Add notification here
        if (!youtube_parser(acLink)) return;

        setLink(acLink);

        // Check if video is already processed
        const audioPath = "https://rudra-public-assets.s3.us-west-1.amazonaws.com/wordband/yt/smallest/" + youtube_parser(acLink) + ".wav";
        fetch(audioPath, { method: 'HEAD' }).then((response) => {
            if (response.ok) {
                setAudioLink(audioPath);
            }
            else {
                ytVisual(acLink).then((response2) => {
                    // console.log(response2);
                    setAudioLink(response2);
                });

            }
        });
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(event);
        }
    };

    function youtube_parser(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : false;
    }

    useEffect(() => {
        if (!audioLink) return;

        const wavesurfer = WaveSurfer.create({
            container: wavesurferRef.current,
            waveColor: theme.colors.gray[8],
            progressColor: theme.colors.gray[2],
            height: 40,
            interact: false,
            plugins: [
                Regions.create({}),
            ],
        });


        wavesurfer.load(audioLink);

        // Initially, set the volume to 0 (muted)
        wavesurfer.media.volume = 0

        // wavesurfer.on('ready', function () {
        //     const duration = wavesurfer.getDuration();
        //     const minutes = Math.floor(duration / 60);
        //     const seconds = Math.floor(duration % 60);
        //     const minutesLabel = minutes < 10 ? "0" + minutes : minutes;
        //     const secondsLabel = seconds < 10 ? "0" + seconds : seconds;
        //     setDurationLabel(minutesLabel + " : " + secondsLabel);
        // });

        wavesurfer.plugins[0].enableDragSelection({
            color: 'rgba(255, 0, 255, 0.3)',
        })

        wavesurfer.plugins[0].on('region-created', (region) => {
            const maxDuration = 15;
            if (region.end - region.start > maxDuration) {
                region.setOptions({
                    end: region.start + maxDuration
                })
            }
            if (wavesurfer.plugins[0].regions.length > 1) {
                wavesurfer.plugins[0].regions[0].remove();
            }
            setSection([region.start, region.end]);
        });

        wavesurfer.plugins[0].on('region-updated', (region) => {
            const maxDuration = 15;
            if (region.end - region.start > maxDuration) {
                region.setOptions({
                    end: region.start + maxDuration
                })
            }
            if (wavesurfer.plugins[0].regions.length > 1) {
                wavesurfer.plugins[0].regions[0].remove();
            }
            setSection([region.start, region.end]);
        });

        wavesurfer.on('ready', () => {
            setWaveLoaded(true);
            setWavesurfer(wavesurfer);
        });

        return () => {
            try {
                wavesurfer.destroy();
                window.onYouTubeIframeAPIReady = null;
            }
            catch {
                // console.log("Error destroying wavesurfer")
            }
        };

    }, [audioLink])

    const onPlayerStateChange = (event) => {
        if (!Wavesurfer) return;
        const videoDuration = videoPlayerRef.current.getDuration();
        // Start the Wavesurfer audio at the same time as the video
        const videoCurrentTime = videoPlayerRef.current.getCurrentTime();
        const correctTime = videoCurrentTime / videoDuration;
        // console.log("psc", Wavesurfer);
        if (Wavesurfer.getCurrentTime() !== correctTime) {
            Wavesurfer.seekTo(videoCurrentTime / videoDuration);
        }

        if (event.data === window.YT.PlayerState.PLAYING && !Wavesurfer.isPlaying()) {
            Wavesurfer.play();

        } else if (event.data === window.YT.PlayerState.PAUSED && Wavesurfer.isPlaying()) {
            Wavesurfer.pause();
        } else if (event.data === window.YT.PlayerState.ENDED) {
            Wavesurfer.stop();
        }
    };

    function generate() {
        setGenerateLoading(true);

        yt({ video: link, split: section, remove_vocals: hasVocals }).then((response) => {
            const base_id = response.id;
            const owner = response.owner;
            navigate("/e/" + owner + "/" + base_id)
        });
    }

    return (
        <>
            <div className="youtube-container">
                {!link && (<form onSubmit={handleSubmit} className="youtube-link-container">
                    <Autocomplete
                        transitionProps={{ transition: 'pop' }}
                        ref={autocompleteRef}
                        icon={<IconYoutube style={{ width: "40px", height: "40px" }} />}
                        placeholder="paste a youtube link..."
                        data={[]}
                        onKeyDown={handleKeyPress}
                    />
                    <br />
                    <span className="youtube-link-button-container">
                        <Button
                            type="submit"
                            styles={(theme) => ({
                                root: {
                                    border: "1px solid " + theme.colors.gray[6],
                                    fontSize: "32px",
                                    fontWeight: 200,
                                },
                                inner: {
                                    padding: "0px 20px",
                                }
                            })}
                        >
                            GO
                        </Button>
                    </span>
                </form>)}

                {link && (
                    audioLink ? (
                        <div className="youtube-content-container">
                            <div className="youtube-video-container">
                                <YTPlayer
                                    videoId={youtube_parser(link)}
                                    opts={{
                                        height: '360',
                                        width: '640'
                                    }}
                                    onReady={(e) => {
                                        videoPlayerRef.current = e.target;
                                    }}
                                    onStateChange={onPlayerStateChange}
                                />
                            </div>
                            <div className="youtube-audio-container">
                                {waveLoaded ? <>Select a 15 second section of the video to generate music from</> : <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}><Loader /><br />Loading video and audio. Choose smaller videos - 15 mins or less - for faster loading</div>}
                                <br />
                                <div ref={wavesurferRef} style={{ width: "100%", height: "40px" }} />
                                <br />
                                {waveLoaded && <span style={{ display: "flex", flexDirection: "row" }}><Switch checked={hasVocals} onChange={(event) => setHasVocals(event.currentTarget.checked)} label="This section has a voice" /> <Tooltip multiline width={200} label="This feature is in beta. You should still choose it for any clip that has voice, but results may be worse than normal."><Badge color="blue" style={{ marginLeft: "20px" }}>beta</Badge></Tooltip></span>}
                            </div>
                        </div>)
                        : <><Loader /><br />This may take a minute...</>
                )}



                <div className="youtube-generate-button-container">
                    {(link && section.length > 0) ? (
                        <>
                            <br />
                            <br />
                            <Button
                                styles={(theme) => ({
                                    root: {
                                        border: "1px solid " + theme.colors.gray[6],
                                        fontSize: "32px",
                                        fontWeight: 200,
                                    },
                                    inner: {
                                        padding: "0px 20px",
                                    }
                                })}
                                onClick={() => generate()}
                            >
                                {generateLoading ? <Loader /> : "GENERATE"}
                            </Button>
                        </>
                    ) : <></>}
                </div>


            </div>
        </>
    );
}

export default Youtube;