import React, { createContext, useContext, useEffect } from 'react';
import ThemeProvider from "@mui/material/styles/ThemeProvider"; // only used for react-material-music-player
import { createTheme } from "@mui/material/styles"; // only used for react-material-music-player

// Music
import Player from 'react-material-music-player';
import { PlayerInterface } from 'react-material-music-player';

// Create a context to hold the reference
const MusicPlayerContext = createContext();

const muiTheme = createTheme({
    palette: {
        mode: "dark",
    },
});


// Create a provider component for the context
export const MusicPlayerProvider = ({ children, value }) => {
    // The value prop will be the reference to the specific component

    const [musicQueued, setMusicQueued] = React.useState(false);

    useEffect(() => {
        PlayerInterface.subscribe((state) => {
            if (state.playlist.length > 0 && state.playlist[0].ID !== "") {
                setMusicQueued(true);
            }
        })
    }, [])

    return (
        <MusicPlayerContext.Provider value={value}>
            {children}

            {/* Theme provider is only used for react-material-music-player */}
            <ThemeProvider theme={muiTheme}>
                {musicQueued && <Player disableDrawer={true} />}
            </ThemeProvider>
        </MusicPlayerContext.Provider>
    );
};

export default MusicPlayerContext;