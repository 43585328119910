import { Autocomplete, NumberInput, Select } from "@mantine/core";
import { Dropzone, DropzoneProps } from '@mantine/dropzone';

const MantineDarkTheme = {
    colorScheme: 'dark',
    fontFamily: 'Inter',
    primaryShade: 9,
    colors: {
        dark: [
            "#C1C2C5",
            "#A6A7AB",
            "#909296",
            "#5C5F66",
            "#373A40",
            "#2C2E33",
            "#25262B",
            "#000000", // bg color
            "#000000",
            "#000000",
        ],
        gray: [
            "#f8f9fa",
            "#f1f3f5",
            "#e9ecef",
            "#dee2e6",
            "#ced4da",
            "#adb5bd",
            "#868e96",
            "#495057",
            "#343a40",
            "#212529",
        ],
    },

    components: {
        Select: {
            defaultProps: {
                variant: "unstyled",
                transitionProps: { transition: 'pop-top-left', duration: 80, timingFunction: 'ease' }
            },
            styles: (theme, params, { variant }) => ({
                label: {
                    color: theme.colors.gray[7],
                    fontSize: "11px",
                },
                dropdown: {
                    backgroundColor: theme.colors.dark[8],
                },
                icon: {
                    paddingRight: "15px",
                    // borderRight: "1px solid " + theme.colors.gray[6],
                    width: "40px",
                    height: "40px"
                },
            })
        },
        Button: {
            defaultProps: {
                variant: "unstyled",
            },
            styles: (theme, params, { variant }) => ({
                root: {
                    border: "1px solid " + theme.colors.gray[6],
                    fontSize: "20px",
                    fontWeight: 200,
                    color: theme.colors.gray[3],
                    backgroundColor: theme.colors.dark[8],
                    border: "none",
                    // on hover, change to gray[4]
                    "&:hover": {
                        color: theme.colors.gray[4],
                        backgroundColor: theme.colors.dark[6],
                    },
                }
            })
        },
        Autocomplete: {
            defaultProps: {
                variant: "unstyled",
            },
            styles: (theme, params, { variant }) => ({
                dropdown: {
                    backgroundColor: theme.colors.dark[8],
                },
                icon: {
                    paddingRight: "15px",
                    borderRight: "1px solid " + theme.colors.gray[6],
                    width: "40px",
                    height: "40px"
                },
                input: {
                    marginLeft: "15px",
                    fontSize: "24px"
                }
            })
        },
        Textarea: {
            styles: (theme, params, { variant }) => ({
                input: {
                    backgroundColor: theme.colors.dark[8],
                    '&:focus': {
                        borderColor: "white",
                    },
                    '&::placeholder': {
                        color: theme.colors.gray[5],
                    },
                }
            })
        },
        NumberInput: {
            defaultProps: {
                variant: "unstyled",
            },
            styles: (theme, params, { variant }) => ({
                label: {
                    color: theme.colors.gray[7],
                    fontSize: "11px",
                }
            })
        },
        Dropzone: {
            styles: (theme, params, { variant }) => ({
                disabled: {
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                    borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2],
                    cursor: 'not-allowed',

                    '& *': {
                        color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[5],
                    },
                },
            })
        }
    }
}

export default MantineDarkTheme;