import { Button, Loader } from '@mantine/core';
import React, { useState, useEffect } from 'react';

import { useRudraAPI } from '../../../../api';

function Variations(props) {

    const { variations, yt, scratch } = useRudraAPI();
    const [generateLoading, setGenerateLoading] = useState(false);

    const details = props.details;

    function generateVariation() {
        setGenerateLoading(true);
        if (details.type === "variation") {
            variations(props.clip, props.owner).then((response) => {
                props.complete();
                setGenerateLoading(false);
            });
        }
        else if (details.type === "yt") {
            yt({ video: details.gen_params[0], split: JSON.parse(details.gen_params[1]), remove_vocals: JSON.parse(details.gen_params[2]) }, details.id).then((response) => {
                props.complete();
                setGenerateLoading(false);
            });
        }
        else if (details.type === "scratch") {
            scratch({ bpm: parseInt(details.gen_params[0]), key: details.gen_params[1], prompt: details.gen_params[2], genres: JSON.parse(details.gen_params[3]), moods: JSON.parse(details.gen_params[4]) }, details.id).then((response) => {
                // console.log(response);
                props.complete();
                setGenerateLoading(false);
            });
        }
    }

    return (
        <div className="variations-container">
            <br />
            <Button onClick={generateVariation} disabled={generateLoading}>{generateLoading ? <Loader /> : "GENERATE"}</Button>
        </div>
    )
}

export default Variations;