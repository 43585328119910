import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

// Styling
import { MantineProvider } from '@mantine/core';
import MantineDarkTheme from './styles/MantineDarkTheme';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons'

// Pages
import Discover from './pages/Discover/Discover';
import Create from './pages/Create/Create';
import Library from './pages/Library/Library';

// Auth
import AuthProvider, { ProtectedRoute } from './auth';

import Scratch from './pages/Create/Scratch/Scratch';
import File from './pages/Create/File/File';

// Custom Providers
import { MusicPlayerProvider } from './components/MusicPlayer/MusicPlayer';
import Youtube from './pages/Create/Youtube/Youtube';
import Detail from './pages/Detail/Detail';
import RudraAPIProvider from './api';
import Launch from './pages/Create/Launch/Launch';
import Video from './pages/Create/Launch/Video/Video';
import Redirect from './pages/Create/Launch/Redirect/Redirect';

// Analytics
import { Analytics } from '@vercel/analytics/react';


library.add(faXTwitter)

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/create" />
  },
  {
    path: "/discover",
    element: (<ProtectedRoute><Discover /></ProtectedRoute>)
  },
  {
    path: "/create",
    element: (<ProtectedRoute><Create /></ProtectedRoute>),
  },
  {
    path: "/create/scratch",
    element: (<ProtectedRoute><Scratch /></ProtectedRoute>),
  },
  {
    path: "/create/file",
    element: (<ProtectedRoute><File /></ProtectedRoute>),
  },
  {
    path: "/create/yt",
    element: (<ProtectedRoute><Youtube /></ProtectedRoute>),
  },
  {
    path: "/e/:owner/:id",
    element: (<ProtectedRoute><Detail /></ProtectedRoute>),
  },
  {
    path: "/library",
    element: (<ProtectedRoute><Library /></ProtectedRoute>),
  },
  {
    path: "/v/:clip",
    element: <Redirect />
    // element: <Launch /> // DEPRECATED
  },
  // {
  //   path: "/embvid/:clip",
  //   element: <Video />
  // }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <MantineProvider theme={MantineDarkTheme} withCSSVariables withGlobalStyles withNormalizeCSS>
    <AuthProvider>
      <RudraAPIProvider>
        <MusicPlayerProvider>
          <RouterProvider router={router} />
          {/* Make a div that always is on the bottom of the screen and takes up 100px. It should fit in the view so that it pushes away other elements still */}
          <div style={{ width: "100%", height: "70px", display: "flex" }}>&nbsp;</div>
          <Analytics />
        </MusicPlayerProvider>
      </RudraAPIProvider>
    </AuthProvider>
  </MantineProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
