import { useEffect, useState, useRef, forwardRef } from 'react';

// Icons
import IconCirclePlay from '../icons/IconPlay';
import IconCirclePause from '../icons/IconPause';
import { LightningBoltIcon } from '@radix-ui/react-icons';

// CSS
import './SongCard.css';
import { useMantineTheme, Text, Modal, Button } from '@mantine/core';

// Music
import WaveSurfer from "wavesurfer.js";
import Player, { PlayerInterface, Track } from 'react-material-music-player';

import { supabase } from '../../auth';

// Components
import { Tooltip } from '@mantine/core';
import IconHeadphonesSimple from '../icons/Headphones';
import IconYoutube from '../icons/Youtube';
import { IconHeart, IconHeartFilled, IconShare, IconX } from '@tabler/icons-react';
import { MagicWandIcon, EnterFullScreenIcon } from '@radix-ui/react-icons';
import { Link, useNavigate } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';

function SongCard(props) {



    let navigate = useNavigate();

    const [playing, setPlaying] = useState(false);
    const [liked, setLiked] = useState(false);

    const wavesurferRef = useRef(null);
    const [durationLabel, setDurationLabel] = useState("");


    const [details, setDetails] = useState(null);
    const [eta, setEta] = useState(null);
    const [etaInterval, setEtaInterval] = useState(null);

    const [opened, { open, close }] = useDisclosure();

    const theme = useMantineTheme();

    function updateDetails() {
        supabase.from("clips").select("*").eq("id", props.id).eq("owner", props.owner).single().then((details) => {
            supabase.from("wb_users").select("*").eq("owner_id", details.data.owner).single().then((user) => {
                details.data.owner_name = user.data.name;
                setDetails(details.data);

                setEtaInterval(setInterval(() => {
                    const currentTimeInS = parseInt(new Date().getTime() / 1000);
                    if (currentTimeInS % 5 == 0) {
                        supabase.from("clips").select("*").eq("id", props.id).eq("owner", props.owner).single().then((details) => {
                            if (details.data == null) {
                                clearInterval(etaInterval);
                                return;
                            }
                            // Update details with new data
                            setDetails((prevDetails) => {
                                return {
                                    ...prevDetails,
                                    ...details.data
                                }
                            });

                            const estRemainingTime = compareEta(details.data.eta);
                            setEta(estRemainingTime)

                            if (estRemainingTime < -60 || details.data.status == 2) {
                                clearInterval(etaInterval);
                            }
                        });
                    }
                    else {
                        setEta((prevEta) => {
                            return prevEta - 1;
                        })
                    }
                }, 1000));
            });
            supabase.from("favorites").select("*").eq("clip", details.data.id).eq("owner", details.data.owner).single().then((favorite) => {
                if (favorite.data) {
                    setLiked(true);
                }
            });
        });
    }

    useEffect(() => {
        updateDetails();
    }, []);

    function renderSource() {
        if (!details) return (<></>);
        if (details.type[0] === "file") {
            const helpText = `Made with custom file (not available)`;
            const HeadphonesIcon = forwardRef(function ({ color }, ref) {
                return (
                    <div ref={ref} color={color}>
                        <IconHeadphonesSimple style={{ height: 30, width: 30 }} />
                    </div>
                );
            });
            return (
                <Tooltip
                    multiline
                    transitionProps={{ transition: 'scale-y', duration: 300 }}
                    label={helpText}
                    color="black"
                    withArrow
                >
                    <HeadphonesIcon />
                </Tooltip>
            )
        }
        else if (details.type[0] === "scratch") {
            const helpText = `Prompt: ${details.source[1]}\nMoods: ${details.source[2]}\nGenres: ${details.source[3]}`;
            return (
                <Tooltip
                    multiline
                    transitionProps={{ transition: 'scale-y', duration: 300 }}
                    label={helpText}
                    color="black"
                    withArrow
                >
                    <LightningBoltIcon style={{ height: 30, width: 30 }} />
                </Tooltip>
            )

        }
        else if (details.type[0] === "youtube") {
            const helpText = `Made with YouTube video: ${details.source[1]}\nStart time: ${details.source[2]}\nEnd time: ${details.source[3]}`;
            const YoutubeIcon = forwardRef(function ({ color }, ref) {
                return (
                    <div ref={ref} color={color}>
                        <IconYoutube style={{ height: 30, width: 30 }} />
                    </div>
                );
            });

            return (
                <Tooltip
                    multiline
                    transitionProps={{ transition: 'scale-y', duration: 300 }}
                    label={helpText}
                    color="black"
                    withArrow
                >
                    <YoutubeIcon />
                </Tooltip>
            )
        }

        return (<Text size="md" style={{ fontWeight: 400, color: theme.colors.gray[3] }}>{details.type}</Text>)
    }

    useEffect(() => {
        if (!details) return;
        if (props.onAudioFound && details.audio) {
            props.onAudioFound();
        }
        // Create a new Wavesurfer instance
        const wavesurfer = WaveSurfer.create({
            container: wavesurferRef.current,
            waveColor: theme.colors.gray[8],
            progressColor: theme.colors.gray[2],
            cursorColor: theme.colors.gray[8],
            height: 40,
            barWidth: 2,
            barGap: 3,

            interact: false,
        });

        // Load audio file
        wavesurfer.load(details.audio);

        // Initially, set the volume to 0 (muted)
        wavesurfer.media.volume = 0

        // Set the duration label as mm:ss. Include at minimum 2 digits for mm
        wavesurfer.on('ready', function () {
            const duration = wavesurfer.getDuration();
            const minutes = Math.floor(duration / 60);
            const seconds = Math.floor(duration % 60);
            const minutesLabel = minutes < 10 ? "0" + minutes : minutes;
            const secondsLabel = seconds < 10 ? "0" + seconds : seconds;
            setDurationLabel(minutesLabel + " : " + secondsLabel);
        });

        PlayerInterface.subscribe((state) => {
            if (details && state.playlist[0].ID === details.id) {
                if (state.mediaState === "PLAYING") {
                    wavesurfer.setOptions({
                        waveColor: theme.colors.gray[0],
                    });
                }
                else {
                    wavesurfer.setOptions({
                        waveColor: theme.colors.gray[8],
                    });
                }
            }
            else {
                wavesurfer.setOptions({
                    waveColor: theme.colors.gray[8],
                });
            }

            if (details && state.mediaState === "PLAYING" && state.playlist[0].ID === details.id) {
                setPlaying(true);
            }
            else {
                setPlaying(false);
            }

            // if (state.playlist[0].ID !== details.id) {
            //     wavesurfer.pause();
            //     try {
            //         wavesurfer.seekTo(0);
            //     }
            //     catch { }
            // } else {
            //     if (state.currentTime - wavesurfer.getCurrentTime() > 1 || state.currentTime - wavesurfer.getCurrentTime() < -1) {
            //         wavesurfer.seekTo(state.currentTime / wavesurfer.getDuration());
            //     }

            //     if (state.mediaState !== "PLAYING" && wavesurfer.isPlaying()) {
            //         wavesurfer.pause();
            //     }
            //     else if (state.mediaState === "PLAYING" && !wavesurfer.isPlaying()) {
            //         wavesurfer.play();
            //     }
            // }
        });

        // Clean up the Wavesurfer instance on component unmount
        return () => {
            try {
                wavesurfer.destroy()
            }
            catch {
                // console.log("wavesurfer already destroyed")
            }
        };
    }, [details]);

    function likeSong() {
        if (!details) return;
        if (liked) {
            supabase.from("favorites").delete().eq("clip", details.id).eq("owner", details.owner).then(() => {
                setLiked(false);
            });
        } else {
            supabase.from("favorites").insert({ clip: details.id, owner: details.owner }).then(() => {
                setLiked(true);
            });
        }
    }

    function deleteSong() {
        supabase.from("clips").delete().eq("id", details.id).eq("owner", details.owner).then((details) => {
            if (props.removeFunc) {
                props.removeFunc();
            }
            close();
        });
    }

    function compareEta(givenETA) {
        if (!givenETA) return 0;
        const today = new Date();
        const etaDate = new Date(givenETA);

        return parseInt((etaDate - today) / 1000);
    }


    function toggleMusicPlay() {
        const current = PlayerInterface.getState();

        if (current.playlist[0].ID === details.id) {
            if (current.mediaState === "PLAYING") {
                PlayerInterface.pause();
                return;
            }
            PlayerInterface.play();
            return;
        }

        PlayerInterface.setPlaylist([new Track(details.id, "", (details.name ? details.name : details.id), details.owner, details.audio)])
        PlayerInterface.play();
    }

    return (
        <>
            <Modal opened={opened} onClose={close} size="md">
                <Text size="lg" style={{ fontWeight: 400, color: theme.colors.gray[0] }}>Are you sure you want to delete song {details ? (details.name ? details.name : details.id) : ""}?</Text>
                <Text size="md" style={{ fontWeight: 400, color: theme.colors.gray[0] }}>This action is irreversible.</Text>
                <br />
                <Button onClick={close}>Never Mind</Button>
                <Button onClick={deleteSong} color='red' variant="filled">Delete</Button>
            </Modal>
            <div className="song-card-container">
                {props.delete &&
                    <span className="delete-button-container">
                        <IconX style={{ height: 20, width: 20 }} onClick={open} />
                    </span>
                }
                {(details && details.audio) && (
                    <span className="play-button-container">
                        {playing ? <IconCirclePause style={{ height: 40, width: 40 }} onClick={toggleMusicPlay} /> :
                            <IconCirclePlay style={{ height: 40, width: 40 }} onClick={toggleMusicPlay} />}
                    </span>
                )}
                {/* <span className="song-title-container">
                    <span className="song-title">
                        <Link to={details ? ("/e/" + details.owner + "/" + details.id) : ""}>{details ? (details.name ? details.name : details.id) : ""}</Link>
                    </span>
                    <span className="song-artist">
                        {details ? details.owner_name : ""}
                    </span>
                </span> */}
                {/* <span className="song-source-container">
                    {renderSource()}
                </span> */}
                {(details && details.status != 2 && details.parent != null) && (
                    <span className="queue-container">
                        {(details && details.status == 0 && details.parent != null) && (<Text size="md" style={{ fontWeight: 400, color: theme.colors.gray[3] }}>QUEUED FOR GENERATION</Text>)}
                        {(details && details.status == 1 && compareEta(details.eta) < -30) && (<Text size="md" style={{ fontWeight: 400, color: theme.colors.gray[3] }}>FAILED</Text>)}
                        {(details && details.status == 1 && compareEta(details.eta) > -30) && (<Text size="md" style={{ fontWeight: 400, color: theme.colors.gray[3] }}>ETA: {Math.max(0, eta)}s</Text>)}
                    </span>
                )}
                <span className="song-waveform-container" style={(details && !details.audio) ? { display: "none" } : {}}>
                    <div ref={wavesurferRef} style={{ width: "100%", height: "40px" }} /> <span className="duration-label">{durationLabel}</span>
                </span>
                <span className="song-actions-container">
                    {/* <IconShare style={{ height: 30, width: 30, marginRight: "5px" }} /> */}
                    {liked ? <IconHeartFilled onClick={likeSong} style={{ height: 30, width: 30, color: theme.colors.red[8] }} /> : <IconHeart onClick={likeSong} style={{ height: 30, width: 30 }} />}
                    <EnterFullScreenIcon style={{ height: 30, width: 30, marginLeft: "5px" }} onClick={() => { navigate(details ? ("/e/" + details.owner + "/" + details.id) : "") }} />
                    {/* <MagicWandIcon style={{ height: 30, width: 30, marginLeft: "15px" }} /> */}
                </span>
            </div >
        </>
    )
}

export default SongCard;