import React, { useState } from 'react'

import './CarouselSelector.css'
import { Carousel } from '@mantine/carousel';

import { useMantineTheme } from '@mantine/core';

function CarouselSelector(props) {
    const theme = useMantineTheme();

    const numSlides = Math.ceil(props.data.length / props.perSlide);

    function toggleSelect(item) {
        if (props.value.includes(item.name)) {
            props.setValue(props.value.filter((i) => i !== item.name));
        } else {
            props.setValue([...props.value, item.name]);
        }
    }

    return (
        <Carousel
            // maw={100 * (Math.min(props.perSlide, props.data.length) * 2 - 1) + 150 + "px"}
            height={200}
            loop
            draggable={false}
            styles={{
                control: {
                    backgroundColor: theme.colors.dark[8],
                    color: theme.colors.gray[0],
                    border: "none",
                },
            }}
        >
            {Array.from({ length: numSlides }, (_, i) => (
                <Carousel.Slide>
                    <div className="slide-container">
                        {
                            props.data.slice(i * props.perSlide, (i + 1) * props.perSlide).map((item) => (
                                <div className={"slide-object-container" + ((props.value.includes(item.name)) ? "" : " not-chosen")} onClick={() => { toggleSelect(item) }}>
                                    <span className="slide-image">
                                        <img src={item.image} style={{ width: "100px", height: "100px", borderRadius: "20px", userSelect: "none" }} draggable={false} />
                                    </span>
                                    <span className="slide-text">
                                        {item.name}
                                    </span>
                                </div>
                            ))
                        }
                    </div>
                </Carousel.Slide>
            ))}
        </Carousel>
    )
}

export default CarouselSelector