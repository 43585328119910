import { useEffect, useState, useRef, forwardRef } from 'react';

// Icons
import IconCirclePlay from '../icons/IconPlay';
import IconCirclePause from '../icons/IconPause';
import { LightningBoltIcon } from '@radix-ui/react-icons';

// CSS
import './SongCardNested.css';
import { useMantineTheme, Text, Modal, Button, Accordion, Group, Loader } from '@mantine/core';

// Music
import WaveSurfer from "wavesurfer.js";
import Player, { PlayerInterface, Track } from 'react-material-music-player';

import { supabase } from '../../auth';

// Components
import SongCard from '../SongCard/SongCard';

function SongCardNested(props) {

    const theme = useMantineTheme();

    const [children, setChildren] = useState([]);

    const [childrenWithAudio, setChildrenWithAudio] = useState([]);

    function updateDetails() {
        // Get children
        supabase.from("clips").select("id, owner, audio").eq("parent", props.owner + props.id).then((collectedChildren) => {
            setChildren(collectedChildren.data);
        });
    }

    useEffect(() => {
        updateDetails();
    }, []);

    useEffect(() => {
        console.log(childrenWithAudio, children)
    }, [childrenWithAudio]);

    return (
        <Accordion.Item key={props.id} value={props.id}>
            <Accordion.Control>
                <Group noWrap className="song-card-nested-group">
                    <div>
                        <Text>{props.title ? props.title : props.subtitle}</Text>
                        <Text size="sm" color="dimmed" weight={400} style={{ marginLeft: "5px" }}>
                            {props.title ? props.subtitle : ""}
                        </Text>
                    </div>
                    {(children.length > 0 && !children.every((child) => childrenWithAudio.includes(child.id))) ? (<Loader size={20} style={{ marginLeft: "25px" }} />) : <></>}
                </Group>
            </Accordion.Control>
            <Accordion.Panel>
                {children.map((child) => {
                    return (<SongCard key={child.id} id={child.id} owner={child.owner} onAudioFound={() => {
                        setChildrenWithAudio((prev) => {
                            if (prev.some((prevChild) => prevChild == child.id)) {
                                return prev;
                            }
                            return [...prev, child.id];
                        })
                    }} />);
                }
                )}
            </Accordion.Panel>
        </Accordion.Item>
    )
}

export default SongCardNested;