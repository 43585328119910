import { useEffect, useState } from 'react';
import { supabase } from '../../auth';

import Header from '../../components/Header/Header';
import Section from '../../components/Section/Section';
import SongCard from '../../components/SongCard/SongCard';
import './Create.css';
import NewSelector from '../../components/NewSelector/NewSelector';
import { Button } from '@mantine/core';

function Create() {

    const [remixes, setRemixes] = useState([]);
    const [remixPaginationCounter, setRemixPaginationCounter] = useState(0);

    const fetchRemixes = async () => {
        const remixesResponse = await supabase.from("clips").select("*, favorites(clip)").order("created_at", { ascending: false }).range(remixPaginationCounter, remixPaginationCounter + 2);
        setRemixPaginationCounter((prev) => prev + remixesResponse.data.length);
        // console.log(remixPaginationCounter, remixesResponse.data);
        setRemixes((prev) => [
            ...prev,
            ...remixesResponse.data
        ]);
    }

    useEffect(() => {
        fetchRemixes();
    }, []);

    return (
        <div className="create-container">
            <Header page="create" />
            <NewSelector />

            {/* <div style={{ width: "80%" }}>
                <div style={{ width: "100%", height: "100px" }}></div>
                <Section title="pick up where you left off">
                    {remixes.map((remix) => {
                        // console.log("remix", remix);
                        return <SongCard key={remix.id} id={remix.id} owner={remix.owner} />
                    })}
                    <Button onClick={fetchRemixes}>load more</Button>
                </Section>
            </div> */}
        </div>
    )
}

export default Create;