import React, { useEffect, useState } from "react";

import { Autocomplete, Button, CloseButton, Loader, NumberInput, Select, Accordion, Textarea, Chip, createStyles, rem, Tooltip, Group, Text } from "@mantine/core";

import Header from "../../../components/Header/Header";
import './Scratch.css';

import { useNavigate, useSearchParams } from "react-router-dom";
import Section from "../../../components/Section/Section";
import { LightningBoltIcon } from "@radix-ui/react-icons";
import { IconPlus } from '@tabler/icons-react';

import { useMantineTheme } from '@mantine/core';
import CarouselSelector from "../../../components/CarouselSelector/CarouselSelector";

import { useRudraAPI } from "../../../api";
import { supabase } from "../../../auth";
import SongCardNested from "../../../components/SongCardNested/SongCardNested";

const allGenres = [
    { "name": "edm", "image": "/assets/gam/edm.png" },
    { "name": "hip hop", "image": "/assets/gam/hiphop.png" },
    { "name": "lofi", "image": "/assets/gam/lofi.png" }
]

const allMoods = [
    { "name": "happy", "image": "/assets/gam/happy.png" },
    { "name": "sad", "image": "/assets/gam/sad.png" },
    { "name": "relaxing", "image": "/assets/gam/relaxing.png" },
    { "name": "sentimental", "image": "/assets/gam/sentimental.png" },
    { "name": "mysterious", "image": "/assets/gam/mysterious.png" },
    { "name": "epic", "image": "/assets/gam/epic.png" },
    { "name": "intense", "image": "/assets/gam/intense.png" },
]


const allKeys = [
    { value: "C", label: "C" },
    { value: "C#", label: "C#" },
    { value: "D", label: "D" },
    { value: "D#", label: "D#" },
    { value: "E", label: "E" },
    { value: "F", label: "F" },
    { value: "F#", label: "F#" },
    { value: "G", label: "G" },
    { value: "G#", label: "G#" },
    { value: "A", label: "A" },
    { value: "A#", label: "A#" },
    { value: "B", label: "B" },
]

const examples = [
    { "prompt": "rap beat", "bpm": "", "key": "", "genres": ["hip hop"], "moods": ["intense"], "color": "rgb(21, 41, 61)" },
    { "prompt": "chill edm", "bpm": "", "key": "", "genres": ["edm"], "moods": ["relaxing"], "color": "rgb(70, 47, 101)" },
    { "prompt": "laid back hip hop", "bpm": "", "key": "", "genres": ["lofi"], "moods": [], "color": "rgb(46, 25, 42)" },
]

const useStyles = createStyles((theme) => ({
    root: {
        // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        borderRadius: theme.radius.sm,
    },

    item: {
        // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        border: `${rem(1)} solid transparent`,
        position: 'relative',
        zIndex: 0,
        transition: 'transform 150ms ease',

        '&[data-active]': {
            transform: 'scale(1.03)',
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            boxShadow: theme.shadows.md,
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],
            borderRadius: theme.radius.md,
            zIndex: 1,
        },
    },

    chevron: {
        '&[data-rotate]': {
            transform: 'rotate(-90deg)',
        },
    },
}));

function Scratch() {

    const theme = useMantineTheme();

    let navigate = useNavigate();
    const { scratch } = useRudraAPI();
    const [generateLoading, setGenerateLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const [prompt, setPrompt] = useState("");
    const [bpm, setBpm] = useState("");
    const [key, setKey] = useState("");
    const [duration, setDuration] = useState(15);
    const [genres, setGenres] = useState([]);
    const [moods, setMoods] = useState([]);

    const [generations, setGenerations] = useState([]);

    const [inpFocused, setInpFocused] = useState(false);


    const { classes } = useStyles();

    useEffect(() => {
        if (searchParams.get("prompt")) {
            setPrompt(searchParams.get("prompt"));
        }
        if (searchParams.get("bpm") && parseInt(searchParams.get("bpm")) !== NaN && parseInt(searchParams.get("bpm")) > 0 && parseInt(searchParams.get("bpm")) < 180) {
            setBpm(parseInt(searchParams.get("bpm")));
        }
        if (searchParams.get("key") && allKeys.map((key) => key.value).includes(searchParams.get("key").toUpperCase())) {
            setKey(searchParams.get("key").toUpperCase());
        }
        if (searchParams.get("genres")) {
            setGenres(searchParams.get("genres").split(","));
        }
        if (searchParams.get("moods")) {
            setMoods(searchParams.get("moods").split(","));
        }
    }, [])

    function generate(params = {}) {
        setGenerateLoading(true);
        if (params.prompt !== undefined || params.genres !== undefined || params.moods !== undefined) {
            // Only keep relevant keys
            params = Object.fromEntries(Object.entries(params).filter(([key, value]) => ["prompt", "genres", "moods", "bpm", "duration", "key"].includes(key)));
        }
        else {
            if (prompt && prompt.length > 0) {
                params["prompt"] = prompt;
            }
            if (bpm && bpm > 0 && bpm < 180) {
                params["bpm"] = bpm;
            }
            if (duration && duration > 0 && duration < 30) {
                params["duration"] = duration;
            }
            if (key && allKeys.map((key) => key.value).includes(key.toUpperCase())) {
                params["key"] = key;
            }
            if (genres.length > 0 && genres.every((genre) => allGenres.map((genre) => genre.name).includes(genre))) {
                params["genres"] = genres;
            }
            if (moods.length > 0 && moods.every((mood) => allMoods.map((mood) => mood.name).includes(mood))) {
                params["moods"] = moods;
            }
        }

        scratch(params).then((response) => {
            const base_id = response.id;
            const owner = response.owner;
            // navigate("/e/" + owner + "/" + base_id)
            clearAll();
            setGenerateLoading(false);
            setGenerations((currentGenerations) => {
                return [{ "id": base_id, "owner": owner, "gen_params": [params.bpm, params.key, params.prompt, JSON.stringify(params.genres), JSON.stringify(params.moods)] }].concat(currentGenerations);
            });
        });
    }

    function getRandomPastelColor() {
        const hue = Math.floor(Math.random() * 360); // Random hue between 0 and 360 degrees
        const saturation = Math.floor(Math.random() * 25) + 25; // Random saturation between 25% and 50%
        const lightness = Math.floor(Math.random() * 25) + 10; // Random lightness between 10% and 35%

        const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

        return color;
    }

    useEffect(() => {
        supabase.from("clips").select("*").eq("type", "scratch").order("created_at", { ascending: false }).limit(3).then((response) => {
            setGenerations(response.data);
        });
    }, [])

    function loadExample(example) {
        setPrompt(example.prompt);
        setGenres(example.genres);
        setMoods(example.moods);
        setBpm(example.bpm);
        setKey(example.key);
        setInpFocused(true);
    }

    function clearAll() {
        setPrompt("");
        setBpm("");
        setKey("");
        setDuration(15);
        setGenres([]);
        setMoods([]);
        setInpFocused(false);
    }

    return (
        <>
            <div className="scratch-container">
                <div className="scratch-search-container">
                    <div className="scratch-autocomplete-container">
                        <Textarea
                            placeholder="type your prompt here..."
                            autosize
                            value={prompt}
                            onChange={(event) => setPrompt(event.currentTarget.value)}
                            minRows={4}
                            maxRows={6}
                            onFocus={() => setInpFocused(true)}
                        />
                        {(inpFocused || prompt.length > 0 || genres.length > 0 || moods.length > 0) && (
                            <div style={{ width: "100%", justifyContent: "flex-end", display: "flex" }}>
                                <Button className="scratch-clear" onClick={clearAll}>clear</Button>
                            </div>
                        )}

                        {inpFocused && (
                            <div className="scratch-chips-container">
                                genres
                                <div className="scratch-genre-chips-container">
                                    <Chip.Group multiple value={genres} onChange={setGenres}>
                                        {allGenres.map((genre) => (
                                            <Chip
                                                key={genre.name}
                                                value={genre.name}
                                                color={genres.includes(genre.name) ? "dark" : "gray"}
                                                variant={genres.includes(genre.name) ? "filled" : "outline"}
                                                style={{ display: "inline-flex", marginRight: "10px" }}
                                            >
                                                {genre.name}
                                            </Chip>
                                        ))}
                                    </Chip.Group>
                                </div>
                                <br />
                                moods
                                <div className="scratch-mood-chips-container">
                                    <Chip.Group multiple value={moods} onChange={setMoods}>
                                        {allMoods.map((mood) => (
                                            <Chip
                                                key={mood.name}
                                                value={mood.name}
                                                color={moods.includes(mood.name) ? "dark" : "gray"}
                                                variant={moods.includes(mood.name) ? "filled" : "outline"}
                                                style={{ display: "inline-flex", marginRight: "10px" }}
                                            >
                                                {mood.name}
                                            </Chip>
                                        ))}
                                    </Chip.Group>
                                </div>
                                <br />
                            </div>
                        )}


                        {/* <Autocomplete
                            transitionProps={{ transition: 'pop' }}
                            icon={<LightningBoltIcon style={{ width: "40px", height: "40px" }} />}
                            placeholder="describe any music you want..."
                            data={["90s rock"]}
                            onChange={(value) => setPrompt(value)}
                            value={prompt}
                        /> */}
                    </div>

                    <div className="scratch-accordion-container">
                        <Accordion
                            chevron={<IconPlus size="1rem" />}
                            styles={{
                                chevron: {
                                    '&[data-rotate]': {
                                        transform: 'rotate(45deg)',
                                    },
                                },
                            }}
                            style={{ display: "flex" }}
                        >
                            <Accordion.Item value="advanced">
                                <Accordion.Control>advanced</Accordion.Control>
                                <Accordion.Panel>
                                    <div className="search-extra-params-container">
                                        <span className="duration-container">
                                            <NumberInput
                                                label={duration ? "duration" : " "}
                                                placeholder="duration"
                                                value={duration}
                                                onChange={(value) => setDuration(value)}
                                                max={30}
                                                min={5}
                                            />
                                        </span>
                                        <span className="bpm-container">
                                            <NumberInput
                                                label={bpm ? "bpm" : " "}
                                                placeholder="bpm"
                                                value={bpm}
                                                onChange={(value) => setBpm(value)}
                                                max={180}
                                                min={0}
                                            />
                                        </span>
                                        <span className="key-container">
                                            <Select
                                                transitionProps={{ transition: 'pop' }}
                                                label={key ? "key" : " "}
                                                placeholder="key"
                                                value={key}
                                                onChange={(value) => setKey(value)}
                                                maxDropdownHeight={170}
                                                clearable
                                                data={allKeys}
                                            />
                                        </span>
                                    </div>
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>

                {inpFocused && (
                    // <Tooltip label={!(prompt || genres.length > 0 || moods.length > 0) ? "type something first!" : ""}>
                    <Button
                        styles={(theme) => ({
                            root: {
                                border: "1px solid " + theme.colors.gray[6],
                                fontSize: "32px",
                                fontWeight: 200,
                            },
                            inner: {
                                padding: "0px 20px",
                            }
                        })}
                        disabled={generateLoading || !(prompt || genres.length > 0 || moods.length > 0)}
                        onClick={() => { generate() }}
                    >
                        {generateLoading ? <Loader /> : "GENERATE"}
                    </Button>
                    // </Tooltip>
                )}

                <br />
                {generations.length > 0 && (
                    <div className="scratch-generations-container">
                        <Section title="generations">
                            <Accordion classNames={classes} className={classes.root} chevronPosition="left">
                                {generations.map((generation) => (
                                    <SongCardNested key={generation.id} id={generation.id} owner={generation.owner} title={generation.gen_params[2]} subtitle={(generation.gen_params[3] ? JSON.parse(generation.gen_params[3]) : []).concat((generation.gen_params[4] ? JSON.parse(generation.gen_params[4]) : [])).join(", ")} />
                                ))}
                            </Accordion>
                            <Button style={{ fontSize: "12px" }}>view all generations...</Button>
                        </Section>
                    </div>
                )}

                <div className="scratch-examples-container">
                    <Section title="examples">
                        {examples.map((example) => (
                            <Group noWrap style={{ backgroundColor: example.color ? example.color : getRandomPastelColor(), width: "90%", marginLeft: "5%", marginBottom: "15px", padding: "5px", justifyContent: "space-between", cursor: "pointer" }} onClick={() => generate(example)}>
                                <div>
                                    <Text style={{ pointerEvents: "none" }}>{example.prompt}</Text>
                                    <Text size="sm" color="dimmed" weight={400} style={{ marginLeft: "5px", pointerEvents: "none" }}>
                                        {example.genres.concat(example.moods).join(", ")}
                                    </Text>
                                </div>
                                <Button size="xs" onClick={(e) => { e.stopPropagation(); loadExample(example) }}>edit</Button>
                            </Group>
                        ))}
                    </Section>
                </div>
                {/* <div className="scratch-genres-container">
                    <Section title="genres">
                        <CarouselSelector data={allGenres} perSlide={5} value={genres} setValue={setGenres} />
                    </Section>
                </div>
                <div className="scratch-moods-container">
                    <Section title="moods">
                        <CarouselSelector data={allMoods} perSlide={5} value={moods} setValue={setMoods} />
                    </Section>
                </div> */}
            </div>
        </>
    )
}

export default Scratch;