import {
    NavLink
} from "react-router-dom";

import './Header.css';

function Header({ page }) {
    return (
        <div className='header'>
            <span className='nav-buttons'>
                {/* <NavLink to="/discover" className={'nav-button' + ((page === "discover") ? ' btn-selected' : '')}>Discover</NavLink> */}
                {/* <NavLink to="/create" className={'nav-button' + ((page === "create") ? ' btn-selected' : '')}>Create</NavLink> */}
                {/* <NavLink to="/library" className={'nav-button' + ((page === "library") ? ' btn-selected' : '')}>Library</NavLink> */}
            </span>
            <span className='logo'>
                <span className='logo-1'>word</span>
                <span className='logo-2'>band</span>
            </span>
            <span className='profile'><img src="/assets/profile_placeholder.png" /></span>
        </div>
    )
}

export default Header;