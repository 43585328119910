import { Text, useMantineTheme } from '@mantine/core';
import './Section.css'

function Section(props) {
    const theme = useMantineTheme();

    return (
        <div>
            <div className="section-container">
                <Text size="xl" style={{ fontWeight: 400, color: theme.colors.gray[6] }}>
                    {props.title}
                </Text>
            </div>
            <div className="section-elements-container">
                {props.children}
            </div>
        </div >
    )
}

export default Section;