import React from "react";

import { useAuth } from "./auth";

const API_BASE_URL = "https://fwvuvii2ki.execute-api.us-west-1.amazonaws.com/wordband";

const RudraAPIContext = React.createContext(null);

const RudraAPIProvider = ({ children }) => {
    const { session } = useAuth();

    const [headers, setHeaders] = React.useState({});

    function addAuthHeaders(headers = {}) {
        if (session) {
            headers['Authorization'] = `Bearer ${session.access_token}`;
        }
        return headers;
    }

    async function getData(endpoint, options = {}) {
        const url = API_BASE_URL + endpoint;
        const response = await fetch(url, addAuthHeaders(options));
        if (!response.ok) {
            throw new Error('Request failed');
        }
        return response.json();
    }

    async function postData(endpoint, data, options = {}) {
        const url = API_BASE_URL + endpoint;
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: addAuthHeaders({
                'Content-Type': 'application/json',
                // Add any additional headers if needed
            }),
            ...options,
        };
        // console.log("Sending POST to " + url + " with request options: " + JSON.stringify(requestOptions));
        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            throw new Error('Request failed');
        }
        return response.json();
    }

    async function head(url) {
        const response = await fetch(url, {
            method: 'HEAD'
        });
        if (!response.ok) {
            throw new Error('Request failed');
        }
        return response;
    }

    async function retry(func, kwargs, delay = 10, timeout = 240) {
        try {
            let response;
            if (Array.isArray(kwargs)) {
                response = await func(...kwargs);
            }
            else {
                response = await func(...Object.values(kwargs));
            }
            if (response.ok !== undefined && !response.ok) {
                throw new Error('Request failed');
            }
            return response;
        } catch (error) {
            if (timeout > 0) {
                await new Promise((resolve) => setTimeout(resolve, delay * 1000));
                return await retry(func, kwargs, delay = delay, timeout = timeout - delay);
            } else {
                throw new Error(`Retried ${func.name} ${timeout / delay} times. Giving up.`);
            }
        }
    }

    async function variations(clip, owner) {
        const response = await postData('/variations', {
            clip: clip,
            owner: owner
        })
        return response;
    }

    async function extend(clip, owner, duration) {
        const response = await postData('/extend', {
            clip: clip,
            owner: owner,
            duration: duration
        })
        return response;
    }

    async function scratch({ bpm, key, duration, prompt, genres, moods }, parent = null) {
        const response = await postData('/scratch', {
            bpm: bpm,
            key: key,
            duration: duration,
            prompt: prompt,
            genres: genres,
            moods: moods,
            parent: parent
        })
        return response;
    }

    async function yt({ video, split, remove_vocals }, parent = null) {
        const response = await postData('/yt', {
            video: video,
            split: split,
            remove_vocals: remove_vocals,
            parent: parent
        })
        return response;
    }

    async function launch({ clip, owner }) {
        const response = await postData('/launch', {
            clip: clip,
            owner: owner
        })
        return response;
    }

    // TODO: Fix later
    async function ytVisual(link) {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ video: link }),
            headers: addAuthHeaders({
                'Content-Type': 'application/json',
                // Add any additional headers if needed
            })
        };
        const response = await fetch("https://harish-kamath--youtube-visual.modal.run", requestOptions);
        if (!response.ok) {
            throw new Error('Request failed');
        }
        return response.json();
    }

    const value = {
        getData,
        postData,
        retry,

        variations,
        extend,

        scratch,
        ytVisual,
        yt,
        launch
    }

    return (
        <RudraAPIContext.Provider value={value}>
            {children}
        </RudraAPIContext.Provider>
    )
}

export const useRudraAPI = () => {
    return React.useContext(RudraAPIContext);
}

export default RudraAPIProvider;