import { Button, Loader, NumberInput, Text } from '@mantine/core';
import React, { useState, useEffect } from 'react';
import './Extend.css';

import { useRudraAPI } from '../../../../api';

function Extend(props) {

    const { extend, yt, scratch } = useRudraAPI();
    const [generateLoading, setGenerateLoading] = useState(false);

    const [extendTime, setExtendTime] = useState("");

    const details = props.details;

    function generateExtend() {
        setGenerateLoading(true);
        if (extendTime < 15 || extendTime > 180) {
            alert("Please enter a valid time between 15 seconds and 3 minutes");
            setGenerateLoading(false);
            return;
        }
        extend(props.clip, props.owner, extendTime).then((response) => {
            props.complete();
            setGenerateLoading(false);
        });
    }

    return (
        <div className="extend-container">
            <br />
            <Text size="md">How much time do you want to add? (between 15 and 180 seconds):</Text>
            <NumberInput style={{ border: "1px solid white" }} value={extendTime} onChange={setExtendTime} />
            <Button onClick={generateExtend} disabled={generateLoading}>{generateLoading ? <Loader /> : "GENERATE"}</Button>
        </div>
    )
}

export default Extend;