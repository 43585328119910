import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

function Redirect() {
    const { clip } = useParams();

    useEffect(() => {
        window.location.href = "https://word.band/v/" + clip;
    }, [clip]);

    return (
        <></>
    );
}

export default Redirect;