import React, { useEffect, useState, useRef } from "react";
import './File.css';

import Header from "../../../components/Header/Header";


import WaveSurfer from "wavesurfer.js";
import Regions from 'wavesurfer.js/dist/plugins/regions.js';


import { useNavigate, useSearchParams } from "react-router-dom";
import { Autocomplete, Button, CloseButton, NumberInput, Select, rem, useMantineTheme, Group, Text } from "@mantine/core";

import { Dropzone, DropzoneProps } from '@mantine/dropzone';
import { IconUpload, IconHeadphones, IconX } from "@tabler/icons-react";


function File() {

    const [file, setFile] = useState(null);
    const [section, setSection] = useState([]);


    let navigate = useNavigate();
    const theme = useMantineTheme();
    const wavesurferRef = useRef(null);
    const [durationLabel, setDurationLabel] = useState("");

    useEffect(() => {
        if (!file) return;
        const wavesurfer = WaveSurfer.create({
            container: wavesurferRef.current,
            waveColor: theme.colors.gray[8],
            progressColor: theme.colors.gray[2],
            height: 40,
            plugins: [
                Regions.create({}),
            ],
        });

        wavesurferRef.current = wavesurfer;


        wavesurferRef.current.loadBlob(file);
        wavesurferRef.current.on('ready', function () {
            const duration = wavesurferRef.current.getDuration();
            const minutes = Math.floor(duration / 60);
            const seconds = Math.floor(duration % 60);
            const minutesLabel = minutes < 10 ? "0" + minutes : minutes;
            const secondsLabel = seconds < 10 ? "0" + seconds : seconds;
            setDurationLabel(minutesLabel + " : " + secondsLabel);
        });

        wavesurferRef.current.plugins[0].enableDragSelection({
            color: 'rgba(255, 0, 255, 0.3)',
        })

        wavesurferRef.current.plugins[0].on('region-created', (region) => {
            const maxDuration = 15;
            if (region.end - region.start > maxDuration) {
                region.setOptions({
                    end: region.start + maxDuration
                })
            }
            if (wavesurferRef.current.plugins[0].regions.length > 1) {
                wavesurferRef.current.plugins[0].regions[0].remove();
            }
            setSection([region.start, region.end]);
        });

        wavesurferRef.current.plugins[0].on('region-updated', (region) => {
            const maxDuration = 15;
            if (region.end - region.start > maxDuration) {
                region.setOptions({
                    end: region.start + maxDuration
                })
            }
            if (wavesurferRef.current.plugins[0].regions.length > 1) {
                wavesurferRef.current.plugins[0].regions[0].remove();
            }
            setSection([region.start, region.end]);
        });

        return () => {
            try {
                wavesurfer.destroy()
            } catch {
                // console.log("wavesurfer already destroyed")
            }
        };

    }, [file])

    return (
        <>
            <Header page="create" />
            <div className="create-section-header">
                <CloseButton iconSize={30} onClick={() => { navigate("/create") }} />
                file
            </div>
            <div className="file-container">
                <div className="file-dropzone-container">
                    <Dropzone
                        onDrop={(file) => { setFile(file[0]); }}
                        onReject={(file) => console.log('rejected files', file)}
                        maxSize={50 * 1024 ** 2}
                        multiple={false}
                        disabled={file}
                        accept={["audio/*"]}
                    >
                        <Group position="center" spacing="xl" style={{ minHeight: rem(220), pointerEvents: 'none' }}>
                            <Dropzone.Accept>
                                <IconUpload
                                    size="3.2rem"
                                    stroke={1.5}
                                    color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Accept>
                            <Dropzone.Reject>
                                <IconX
                                    size="3.2rem"
                                    stroke={1.5}
                                    color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Reject>
                            <Dropzone.Idle>
                                <IconHeadphones size="3.2rem" stroke={1.5} />
                            </Dropzone.Idle>
                            {!file && (
                                <div>
                                    <Text size="xl" inline>
                                        Drag audio file here (or click to select files)
                                    </Text>
                                    <Text size="sm" color="dimmed" inline mt={7}>
                                        Accepted formats: mp3, wav, ogg, aac, m4a, flac
                                    </Text>
                                </div>
                            )}

                            {file && (
                                <div>
                                    <Text size="xl" inline>
                                        Selected {file.name}
                                    </Text>
                                </div>
                            )}

                        </Group>
                    </Dropzone>
                </div>

                {file && (
                    <>
                        Select a section of the song to use (15s or less)
                        <span className="song-waveform-container">
                            <div ref={wavesurferRef} style={{ width: "100%", height: "40px" }} /> <span className="duration-label">{durationLabel}</span>
                        </span>
                    </>
                )}
                <br />
                <div className="file-generate-button-container">
                    {(file && section.length > 0) ? (
                        <Button
                            styles={(theme) => ({
                                root: {
                                    border: "1px solid " + theme.colors.gray[6],
                                    fontSize: "32px",
                                    fontWeight: 200,
                                },
                                inner: {
                                    padding: "0px 20px",
                                }
                            })}
                        >
                            GENERATE
                        </Button>
                    ) : <></>}
                </div>
            </div>
        </>
    );
}

export default File;