import React from "react";
import { LightningBoltIcon } from "@radix-ui/react-icons";

function Scratch(props) {
    const details = props.details.gen_params;

    const bpm = details[0];
    const key = details[1];
    let prompt = details[2];
    const genres = JSON.parse(details[3]);
    const moods = JSON.parse(details[4]);

    if (prompt && prompt.length > 30) {
        // Add ellipsis
        prompt = prompt.substring(0, 30) + "...";
    }

    const emojiHeight = (bpm ? 11 : 0) + (key ? 11 : 0) + (prompt ? 19 : 0) + (genres ? 15 : 0) + (moods ? 15 : 0);

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "10px", marginBottom: "10px" }}>
            <LightningBoltIcon style={{ width: emojiHeight, height: emojiHeight }} />
            <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
                {prompt && <div style={{ fontSize: "18px", color: "white" }}>{prompt}</div>}
                {bpm && <div style={{ fontSize: "10px", color: "white" }}>{bpm} BPM</div>}
                {key && <div style={{ fontSize: "10px", color: "white" }}>{key}</div>}
                {genres && <div style={{ fontSize: "14px", color: "white" }}>{genres.join(", ")}</div>}
                {moods && <div style={{ fontSize: "14px", color: "white" }}>{moods.join(", ")}</div>}
                {/* <a href={details.gen_params[0]} target="_blank" referrerPolicy="noreferrer" style={{ fontSize: "18px", color: "white", textDecoration: "underline" }}>{youtube_parser(details.gen_params[0])}</a> */}
            </div>
        </div>
    )
}

export default Scratch;