import React from "react";
import IconYoutube from "../../../../components/icons/Youtube";

function Youtube(props) {
    const details = props.details;

    function youtube_parser(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : false;
    }

    function showTime() {
        const startTimeInS = parseInt(JSON.parse(details.gen_params[1])[0])
        const endTimeInS = parseInt(JSON.parse(details.gen_params[1])[1])

        // Show time in format 00:00
        const startTimeMinutes = Math.floor(startTimeInS / 60);
        const startTimeSeconds = Math.floor(startTimeInS % 60);
        const startTimeMinutesLabel = startTimeMinutes < 10 ? "0" + startTimeMinutes : startTimeMinutes;
        const startTimeSecondsLabel = startTimeSeconds < 10 ? "0" + startTimeSeconds : startTimeSeconds;

        const endTimeMinutes = Math.floor(endTimeInS / 60);
        const endTimeSeconds = Math.floor(endTimeInS % 60);
        const endTimeMinutesLabel = endTimeMinutes < 10 ? "0" + endTimeMinutes : endTimeMinutes;
        const endTimeSecondsLabel = endTimeSeconds < 10 ? "0" + endTimeSeconds : endTimeSeconds;

        return startTimeMinutesLabel + ":" + startTimeSecondsLabel + " - " + endTimeMinutesLabel + ":" + endTimeSecondsLabel;
    }

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <IconYoutube style={{ width: 50, height: 50 }} />
            <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
                <a href={details.gen_params[0]} target="_blank" referrerPolicy="noreferrer" style={{ fontSize: "18px", color: "white", textDecoration: "underline" }}>{youtube_parser(details.gen_params[0])}</a>
                <div style={{ fontSize: "12px" }}>{showTime()}</div>
            </div>
        </div>
    )
}

export default Youtube;