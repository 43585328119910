import React, { useEffect } from "react";
import { createClient } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'
import {
    // Import predefined theme
    ThemeSupa,
} from '@supabase/auth-ui-shared'

const supabaseUrl = "https://mcxuukzoiqvcbjxbjzma.supabase.co"; //import.meta.env.VITE_SUPABASE_URL
const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1jeHV1a3pvaXF2Y2JqeGJqem1hIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODI4MTQ5MTAsImV4cCI6MTk5ODM5MDkxMH0.8oFGot8I5OyrCfoCbK_NfJbUbpdJxDDvFic8GtaoWFI"; //import.meta.env.VITE_SUPABASE_ANON_KEY

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

const AuthContext = React.createContext(null);

const AuthProvider = ({ children }) => {
    const [session, setSession] = React.useState(null)

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
        })

        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })

        return () => subscription.unsubscribe()
    }, [])

    const handleSignOut = async () => {
        try {
            await supabase.auth.signOut();
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };


    const value = {
        session: session,
        handleSignOut: handleSignOut
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return React.useContext(AuthContext);
}

export const ProtectedRoute = ({ children }) => {
    const { session } = useAuth();

    if (!session) {
        // return <>
        //     <div style={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
        //         <span style={{ marginBottom: "20px" }}>
        //             <span className='logo-1'>w</span>
        //             <span className='logo-2'>b</span>
        //         </span>
        //         <span style={{ fontSize: "3rem" }}>coming soon</span>
        //         <a href="https://discord.gg/sRK5v7aafQ" style={{ textDecoration: "none", marginTop: "50px", padding: "10px", border: "1px solid white", color: "inherit" }}>join the discord</a>
        //     </div>
        // </>
        return <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} providers={['google']} onlyThirdPartyProviders={true} />
    }

    return children;
}

export default AuthProvider;

// Usage: const { token } = useAuth();