import React, { useState } from 'react';
import './NewSelector.css'

import { Button, Menu, Text, useMantineTheme, Group, Avatar, Select } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import {
    IconPlus
} from '@tabler/icons-react';

import { LightningBoltIcon, MagicWandIcon } from '@radix-ui/react-icons';

import IconYoutube from '../icons/Youtube';
import IconHeadphonesSimple from '../icons/Headphones';
import { IconChevronDown } from '@tabler/icons-react';

import { useNavigate } from "react-router-dom";
import Scratch from '../../pages/Create/Scratch/Scratch';
import Youtube from '../../pages/Create/Youtube/Youtube';

const SelectItem = React.forwardRef(function (
    { icon, label, value, description, ...others },
    ref
) {
    return React.createElement(
        'div',
        { ref: ref, ...others },
        React.createElement(
            Group,
            { noWrap: true },
            icon,
            React.createElement(
                'div',
                null,
                React.createElement(Text, { size: 'sm' }, label),
                React.createElement(
                    Text,
                    { size: 'xs', opacity: 0.65 },
                    description
                )
            )
        )
    );
});

function NewSelector() {
    const theme = useMantineTheme();
    let navigate = useNavigate();
    const [opened, { open, close }] = useDisclosure();
    const [wordbandLink, setWordbandLink] = useState("");

    const [type, setType] = useState("prompt");

    const menuItems = [
        {
            "icon": <LightningBoltIcon style={{ height: 30, width: 30 }} />,
            "label": "prompt",
            "value": "prompt",
            "description": "Prompt for new music with text",
            "destination": () => setType("prompt")
        },
        {
            "icon": <IconYoutube style={{ height: 30, width: 30 }} />,
            "label": "youtube",
            "value": "yt",
            "description": "Remix music from a YouTube video",
            "destination": () => setType("youtube")
        },
        // {
        //     "icon": <IconHeadphonesSimple style={{ height: 30, width: 30 }} />,
        //     "title": "File",
        //     "description": "Remix music from your own file",
        //     "destination": () => navigate("/create/file")
        // },
        // {
        //     "icon": <MagicWandIcon style={{ height: 30, width: 30 }} />,
        //     "title": "Remix",
        //     "description": "Mix an existing wordband song",
        //     "destination": open
        // },
    ]

    const renderType = () => {
        if (type == "prompt") {
            return <Scratch />
        }
        else if (type == "yt") {
            return <Youtube />
        }
    }

    return (
        <>
            {/* <Modal opened={opened} onClose={close} title="remix">
                <div className='remix-modal-container'>
                    <Autocomplete
                        transitionProps={{ transition: 'pop' }}
                        icon={<MagicWandIcon style={{ width: "40px", height: "40px" }} />}
                        placeholder="wordband link"
                        data={[]}
                        onChange={(value) => setWordbandLink(value)}
                        value={wordbandLink}
                        style={{ marginBottom: "40px" }}
                    />
                    {wordbandLink && (
                        <Button
                            styles={(theme) => ({
                                root: {
                                    border: "1px solid " + theme.colors.gray[6],
                                    fontSize: "32px",
                                    fontWeight: 200,
                                },
                                inner: {
                                    padding: "0px 20px",
                                }
                            })}
                        >
                            GO
                        </Button>
                    )}
                </div>
            </Modal> */}
            <div className="new-selector-container">
                {/* <Select
                    label="mode"
                    placeholder=""
                    itemComponent={SelectItem}
                    data={menuItems}
                    value={type}
                    // icon={menuItems.filter((item) => item.value === type)[0].icon}
                    onChange={(value) => setType(value)}
                    maxDropdownHeight={400}
                    style={{ marginBottom: "20px" }}
                /> */}&nbsp;
            </div>

            {renderType()}

            {/* <Menu
                transitionProps={{ transition: 'pop' }}
                position="bottom"
                width={"80%"}
                withinPortal
            >
                <Menu.Target>
                    <Button leftIcon={<IconPlus size="1.05rem" stroke={1.5} style={{ color: theme.colors.gray[0] }} />} pr={12}>
                        <Text size="xl" style={{ fontWeight: 400, color: theme.colors.gray[0] }}>create new</Text>
                    </Button>
                </Menu.Target>
                <Menu.Dropdown style={{ backgroundColor: theme.colors.dark[8] }}>
                    {menuItems.map((item) => (
                        (
                            <Menu.Item
                                icon={item.icon}
                                onClick={item.destination}
                            >
                                <Group noWrap
                                    style={{ marginLeft: "10px" }}>
                                    <div>
                                        <Text size="xl" style={{ fontWeight: 400, color: theme.colors.gray[1] }}>{item.title}</Text>
                                        <Text size="md" opacity={0.5} style={{ fontWeight: 400, color: theme.colors.gray[3] }}>
                                            {item.description}
                                        </Text>
                                    </div>
                                </Group>
                            </Menu.Item>
                        )
                    ))}
                </Menu.Dropdown>
            </Menu> */}
        </>
    );
}

export default NewSelector;