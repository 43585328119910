import React, { useState, useRef, useEffect } from "react";
import './Detail.css';

import { Link, useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import Section from "../../components/Section/Section";


import Player, { PlayerInterface, Track } from 'react-material-music-player';

import { supabase, useAuth } from "../../auth";
import { useRudraAPI } from "../../api";


import { Share } from "tabler-icons-react";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronCompactDown, IconChevronDown, IconDownload, IconHeart, IconHeartFilled, IconPencil } from "@tabler/icons-react";
import IconCirclePlay from "../../components/icons/IconPlay";
import IconCirclePause from "../../components/icons/IconPause";
import Youtube from "./Sources/Youtube/Youtube";
import { ActionIcon } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { IconCopy } from "@tabler/icons-react";

import { Button, useMantineTheme, Menu, Text, Group, TextInput, Modal, CopyButton, Tooltip } from "@mantine/core";


import WaveSurfer from "wavesurfer.js";
import { useInputState } from "@mantine/hooks";

import Variations from "./Types/Variations/Variations";
import Scratch from "./Sources/Scratch/Scratch";
import Extend from "./Types/Extend/Extend";
import { TwitterIcon } from "@mantine/ds";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SongCard from "../../components/SongCard/SongCard";

const menuItems = [
    {
        "title": "variations",
        "id": "variations",
        "description": "Create a variation of this clip",
        "types": []
    },
    {
        "title": "extend",
        "id": "extend",
        "description": "Make this clip longer",
        "types": ["variation", "extend"]
    },
]

const Detail = () => {
    const { owner, id } = useParams();

    const [details, setDetails] = useState({});
    const [videoLink, setVideoLink] = useState(null);
    const [opened, { open, close }] = useDisclosure(false);

    const [liked, setLiked] = useState(false);

    const [editingName, setEditingName] = useState(false);
    const [tmpName, setTmpName] = useInputState("");
    const [nameInputHover, setNameInputHover] = useState(false);

    const [remixOptionId, setRemixOptionId] = useState(null);

    const theme = useMantineTheme();

    const [remixes, setRemixes] = useState([]);
    const [remixPaginationCounter, setRemixPaginationCounter] = useState(0);

    const { launch, head } = useRudraAPI();

    const [playing, setPlaying] = useState(false);
    const wavesurferRef = useRef(null);
    const [waveSurfer, setWaveSurfer] = useState(null);
    const [durationLabel, setDurationLabel] = useState("");

    const launch_host = ["http://localhost:3000/v/", "http://wrbnd.com/", "http://wrdbnd.com/", "http://word.band/v/"][2];


    async function getDetails() {
        supabase.from("clips").select("*").eq("id", id).eq("owner", owner).single().then((details) => {
            supabase.from("wb_users").select("*").eq("owner_id", owner).single().then((user) => {
                details.data.owner_name = user.data.name;
                setDetails(details.data);
                setTmpName(details.data.title ? details.data.title : details.data.id);

                supabase.from("favorites").select("*").eq("clip", details.data.id).eq("owner", details.data.owner).single().then((favorite) => {
                    if (favorite.data) {
                        setLiked(true);
                    }
                    else {
                        setLiked(false);
                    }
                });
            });
        });

        fetchRemixes();

        // supabase.from("clips").select("id,owner").eq("parent", owner + id).order("created_at", { "ascending": false }).then((remixes) => {
        //     setRemixes(remixes.data);
        // });
    }

    const fetchRemixes = async () => {
        const remixesResponse = await supabase.from("clips").select("id,owner", { count: "exact" }).eq("parent", owner + id).order("created_at", { "ascending": false }).range(remixPaginationCounter, remixPaginationCounter + 4);
        if (remixesResponse.data === null) return;
        setRemixPaginationCounter((prev) => prev + remixesResponse.data.length);
        setRemixes((prev) => [
            ...prev,
            ...remixesResponse.data
        ]);
    }

    useEffect(() => {
        // const fetch = async () => {
        //     const userResponse = await supabase.from("wb_users").select("*").eq("owner_id", owner).single();
        //     console.log("bumping", userResponse);
        // }
        // fetch();
        setRemixes([]);
        setRemixPaginationCounter(0);
        getDetails();
    }, [owner, id])

    // useEffect(() => {
    //     if (!details) return;
    //     PlayerInterface.subscribe((state) => {
    //         if (state.mediaState === "PLAYING" && state.playlist[0].ID === details.id) {
    //             setPlaying(true);
    //         }
    //         else {
    //             setPlaying(false);
    //         }
    //     });
    // }, [details]);

    useEffect(() => {
        if (!details) return;
        if (!details.audio) return;
        if (!wavesurferRef.current) return;

        const wavesurfer = WaveSurfer.create({
            container: wavesurferRef.current,
            waveColor: theme.colors.gray[8],
            progressColor: theme.colors.gray[2],
            height: 40,
        });

        setWaveSurfer(wavesurfer);


        wavesurfer.load(details.audio);
        wavesurfer.on('ready', function () {
            const duration = wavesurfer.getDuration();
            const minutes = Math.floor(duration / 60);
            const seconds = Math.floor(duration % 60);
            const minutesLabel = minutes < 10 ? "0" + minutes : minutes;
            const secondsLabel = seconds < 10 ? "0" + seconds : seconds;
            setDurationLabel(minutesLabel + " : " + secondsLabel);
            setDetails((prev) => {
                return {
                    ...prev,
                    duration: duration
                }
            });
        });

        wavesurfer.on('play', function () {
            setPlaying(true);
        });
        wavesurfer.on('pause', function () {
            setPlaying(false);
        });

        wavesurfer.on('destroy', function () {
            setPlaying(false);
        });

        // PlayerInterface.subscribe((state) => {
        //     if (state.playlist[0].ID !== details.id) {
        //         wavesurfer.pause();
        //         wavesurfer.seekTo(0);
        //     } else {
        //         if (state.currentTime - wavesurfer.getCurrentTime() > 1 || state.currentTime - wavesurfer.getCurrentTime() < -1) {
        //             wavesurfer.seekTo(state.currentTime / wavesurfer.getDuration());
        //         }

        //         if (state.mediaState !== "PLAYING" && wavesurfer.isPlaying()) {
        //             wavesurfer.pause();
        //         }
        //         else if (state.mediaState === "PLAYING" && !wavesurfer.isPlaying()) {
        //             wavesurfer.play();
        //         }
        //     }
        // });

        return () => {
            try {
                wavesurfer.destroy()
            } catch {
                // console.log("wavesurfer already destroyed")
            }
        };

    }, [details.audio])

    function toggleMusicPlay() {
        waveSurfer.playPause();
        // const current = PlayerInterface.getState();

        // if (current.playlist[0].ID === details.id) {
        //     if (current.mediaState === "PLAYING") {
        //         PlayerInterface.pause();
        //         return;
        //     }
        //     PlayerInterface.play();
        //     return;
        // }

        // PlayerInterface.setPlaylist([new Track(details.id, "", details.name, details.owner, details.audio)])
        // PlayerInterface.play();
    }

    function renderSource() {
        if (!details) return <></>;
        if (!details.type) return <></>;
        if (details.type === "yt") return <Youtube details={details} />
        if (details.type === "scratch") return <Scratch details={details} />
        if (details.type === "variation") return <>variation of <Link to={"/e/" + details.parent.substring(0, 5) + "/" + details.parent.substring(5)} style={{ color: "white" }}>{details.parent.substring(5)}</Link></>;
    }

    function showRemixOption() {
        if (!remixOptionId) return <></>;
        if (remixOptionId === "variations") return <Variations details={details} clip={details.id} owner={details.owner} complete={() => { setRemixOptionId(null); window.location.reload() }} />;
        if (remixOptionId === "extend") return <Extend details={details} clip={details.id} owner={details.owner} complete={() => { setRemixOptionId(null); window.location.reload() }} />;
    }

    function changeSongName(e) {
        e.stopPropagation();
        supabase.from("clips").update({ title: tmpName }).eq("id", id).eq("owner", owner).then(() => {
            async function tmpNameChange() {
                await getDetails();
                setEditingName(false);
            }
            tmpNameChange();
        });
    }

    function removeRemix(remixId) {
        setRemixes(remixes.filter((remix) => remix.id !== remixId));
    }

    function likeSong() {
        if (!details) return;
        if (liked) {
            supabase.from("favorites").delete().eq("clip", details.id).eq("owner", details.owner).then(() => {
                setLiked(false);
            });
        } else {
            supabase.from("favorites").insert({ clip: details.id, owner: details.owner }).then(() => {
                setLiked(true);
            });
        }
    }

    function renderAudio() {
        const playButton = playing ? <IconCirclePause style={{ height: 40, width: 40, marginRight: "10px" }} onClick={toggleMusicPlay} /> : ((details && details.audio) ? <IconCirclePlay style={{ height: 40, width: 40, marginRight: "10px" }} onClick={toggleMusicPlay} /> : <></>)
        return (
            <>
                {playButton}
                <div ref={wavesurferRef} style={{ width: "100%", height: "40px" }} />
                <span className="duration-label">{durationLabel}</span>
                <a href={details.audio} style={{ textDecoration: "none", color: "inherit" }}><IconDownload style={{ marginLeft: "10px" }} size={30} /></a>
            </>
        )
    }

    function launchClip() {
        if (!details.audio) {
            alert("The audio for this clip has not been generated yet! Please check back after it is finished.")
            return;
        }

        launch({ clip: details.id, owner: details.owner }).then(() => {
            getDetails();
        })
    }

    useEffect(() => {
        if (!details) return;

        const vidInterval = setInterval(() => {
            if (details.launched) {
                supabase.from("launches").select("video").eq("id", details.id).eq("owner", details.owner).single().then((launch) => {
                    if (launch.data && launch.data.video) {
                        setVideoLink(launch.data.video);
                        clearInterval(vidInterval);
                    }
                });
            }
        }, 2000);
    }, [details]);

    function renderLaunchModal() {
        if (!details.launched) {
            return <>
                <div className="launch-modal-container">
                    <Text size="xl" style={{ fontWeight: 400, color: theme.colors.gray[0], marginBottom: "20px" }}>share your song</Text>

                    <Text size="sm">name your masterpiece before launching</Text>
                    <div className="detail-title" onMouseEnter={() => setNameInputHover(true)} onMouseLeave={() => setNameInputHover(false)}>
                        {!editingName && (details ? (details.title ? details.title : details.id) : "")}
                        {(!editingName) && <IconPencil size={24} style={{ marginLeft: "10px", color: theme.colors.gray[6] }} onClick={() => setEditingName(true)} />}

                        {editingName && (<><TextInput
                            variant="unstyled"
                            on
                            onChange={setTmpName}
                            value={tmpName}
                        /> <Button variant="link" onClick={changeSongName}>change name</Button></>)}
                    </div>

                    <Tooltip label="The audio for this clip has not been generated yet! Please check back after it is finished." disabled={details.audio} width={200} multiline>
                        <Button className="launch-button" style={{ border: "1px solid white", marginTop: "20px", color: "white" }} onClick={launchClip}>share</Button>
                    </Tooltip>
                </div>
            </>
        }

        const launchLink = `${launch_host}${details.owner}${details.id}`

        const twitterContent = "Check out this cool song I made on wordband! " + launchLink
        const twitterLink = `https://twitter.com/intent/tweet?text=` + encodeURIComponent(twitterContent)

        return <>
            <div className="launch-modal-container">
                your song is ready!
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <a href={launchLink} className="launch-modal-url" target="_blank">{launchLink}</a>
                    <CopyButton value={launchLink} timeout={2000} >
                        {({ copied, copy }) => (
                            <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                                <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                                    {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                                </ActionIcon>
                            </Tooltip>
                        )}
                    </CopyButton>
                </div>
                <br />
                <Button
                    leftIcon={<IconDownload />}
                    onClick={() => {
                        window.open(details.audio, "_blank");
                    }}
                >
                    download song
                </Button>
                <br />
                {videoLink ? <video src={videoLink} style={{ width: "100%", height: "auto" }} controls /> : <span style={{ color: "#5c5c5c", fontSize: "10px" }}>Video is processing. Check back later.</span>}

                <br />
                share on social media
                <br />
                <br />
                <a href={twitterLink} style={{ textDecoration: "none", color: "inherit" }} target="_blank" referrerPolicy="noreferrer" >
                    <FontAwesomeIcon icon="fa-brands fa-x-twitter" size="2xl" />
                </a>
            </div >

        </>
    }

    return (
        <>
            <Modal opened={opened} onClose={close} >
                {renderLaunchModal()}
            </Modal>
            <Header page="create" />
            <div className="detail-container">
                <div className="detail-header">
                    <div className="detail-title" onMouseEnter={() => setNameInputHover(true)} onMouseLeave={() => setNameInputHover(false)}>
                        {!editingName && (details ? (details.title ? details.title : details.id) : "")}
                        {(!editingName && (nameInputHover || opened)) && <IconPencil size={24} style={{ marginLeft: "10px", color: theme.colors.gray[6] }} onClick={() => setEditingName(true)} />}

                        {editingName && (<><TextInput
                            variant="unstyled"
                            on
                            onChange={setTmpName}
                            value={tmpName}
                        /> <Button variant="link" onClick={changeSongName}>change name</Button></>)}
                    </div>
                    <div className="detail-artist">
                        {details ? details.owner_name : ""}
                    </div>
                    <div className="detail-buttons">
                        {(details && !(['yt', 'scratch'].includes(details.type))) && <Share size={24} onClick={open} />}
                        {liked ? <IconHeartFilled onClick={likeSong} size={24} style={{ color: theme.colors.red[8] }} /> : <IconHeart onClick={likeSong} size={24} />}
                    </div>
                    <div className="detail-source">
                        {renderSource()}
                    </div>
                </div>
                {(details && details.audio) && <div className="detail-audio">
                    {renderAudio()}
                </div>}
                {details && <><Menu
                    transitionProps={{ transition: 'pop' }}
                    position="bottom"
                    withinPortal
                >
                    <Menu.Target>
                        <div className="detail-remix-button">
                            <Button rightIcon={<IconChevronDown width={20} height={40} />} pr={12}>
                                <Text size="xl" style={{ fontWeight: 400, color: theme.colors.gray[0] }}>{remixOptionId ? remixOptionId : "remix this song"}</Text>
                            </Button>
                        </div>
                    </Menu.Target>
                    <Menu.Dropdown style={{ backgroundColor: theme.colors.dark[8] }}

                    >
                        {menuItems.filter(item => {
                            return details && (item.types.includes(details.type) || item.types.length === 0);
                        }).map((item) => (
                            (
                                <Menu.Item
                                    // icon={item.icon}
                                    onClick={() => { setRemixOptionId(item.id) }}
                                    key={item.id}
                                >
                                    <Group noWrap
                                        style={{ marginLeft: "10px" }}>
                                        <div>
                                            <Text size="xl" style={{ fontWeight: 400, color: theme.colors.gray[1] }}>{item.title}</Text>
                                            <Text size="md" opacity={0.5} style={{ fontWeight: 400, color: theme.colors.gray[3] }}>
                                                {item.description}
                                            </Text>
                                        </div>
                                    </Group>
                                </Menu.Item>
                            )
                        ))}
                    </Menu.Dropdown>
                </Menu>
                    <div className="detail-remix-option">
                        {showRemixOption()}
                    </div>
                    <div className="detail-remix-container">
                        <Section title="remixes">
                            {remixes.map((remix) => (
                                <SongCard key={remix.id} id={remix.id} owner={remix.owner} delete={true} removeFunc={() => { removeRemix(remix.id) }} />
                            ))}
                            <Button onClick={fetchRemixes}>load more</Button>
                        </Section>
                    </div></>}
            </div>
        </>
    );
}

export default Detail;